<template>
  <div style="height: 100%;width: 100%;" class="flex flex-column">
    <div style="height: 60px;border-bottom: #EFF1F4 solid 1px;padding-left: 20px;padding-right: 20px;" class="flex flex-jsb">
        <div class="flex-ajc">
          <div style="font-weight: bolder;font-size: 0.55rem;color: #9B9EA2;">Redid</div>
          <div style="font-weight: bold;font-size: 0.38rem;color: #9B9EA2;margin-left: 4px;">开放平台</div>
        </div>
        <div class="flex-ajc">
          <div style="font-weight: bolder;font-size: 0.3rem;padding-right: 20px;color: #409EFF;">亲爱的{{this.$store.state.user.UserInfo.name}},欢迎使用redid系统</div>
          <el-button circle icon="el-icon-s-home" size="mini" style="margin-right: 8px;" @click="toOverview"/>
          <!-- websocket通信插件 -->
          <!--          <web-socket></web-socket>-->
          <login-account-button></login-account-button>
        </div>
    </div>
    <div class="home flex1 flex">
      <!-- 左边栏目 -->
      <div class="aside" v-if="!single">
        <!-- 菜单 -->
        <el-menu :default-active="getActive($route.path)" exact router class="el-menu-vertical-demo"
                 @open="handleOpen"
                 @close="handleClose"
                 unique-opened
                 :collapse="isCollapse">
          <template v-for="(item) in menuData">
            <!-- 有子菜单的项 -->
            <el-submenu v-if="!item.hidden && item.children && item.children.length > 0 && item.alwaysShow" :index="item.path"
                        :key="item.path">
              <template v-slot:title>
                <i :class="item.meta.icon?item.meta.icon:'el-icon-connection'"></i>
                <span>{{ item.meta.title }}</span>
              </template>
              <el-menu-item v-for="(child) in item.children" :key="child.path" :index="item.path+'/'+child.path"
                            v-if="!child.hidden">
                <i :class="child.meta.icon?child.meta.icon:item.meta.icon"></i>
                <span slot="title">{{ child.meta.title }}</span>
              </el-menu-item>
            </el-submenu>
            <!-- alwaysShow为false的 -->
            <el-menu-item v-if="!item['alwaysShow'] && item.children && item.children.length > 0" :index="item.children[0].path"
                          :key="item.children[0].path">
              <i :class="item.meta.icon?item.meta.icon:''"></i>
              <span slot="title">{{ item.meta.title }}</span>
            </el-menu-item>
            <!-- path为“/”的项  加载子项-->
            <el-menu-item v-if="item.path === '/' && item.children && item.children.length > 0" :index="item.children[0].path"
                          :key="item.children[0].path">
              <i :class="item.children[0].meta.icon?item.children[0].meta.icon:'el-icon-connection'"></i>
              <span slot="title">{{ item.children[0].meta.title }}</span>
            </el-menu-item>
          </template>
          <el-menu-item @click="logout">
            <i class="el-icon-back"></i>
            <span slot="title">退出</span>
          </el-menu-item>
        </el-menu>
      </div>
      <!-- 右边内容区 -->
      <div class="view-container">
        <!-- 头部 -->
        <div class="page-header" v-if="!single" v-show="showBread">
          <!-- 头部左边 -->
          <div class="header-left">
            <!-- 折叠控件 -->
            <div class="fold">
              <i v-show="!isCollapse" class="el-icon-s-fold" @click="isCollapse=!isCollapse"></i>
              <i v-show="isCollapse" class="el-icon-s-unfold" @click="isCollapse=!isCollapse"></i>
            </div>
            <!-- 面包屑 -->
            <div class="bread">
						<span><i class="el-icon-location"></i>
							当前位置：</span>
              <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: item.path }" v-for="(item) in breadData" :key="item.path">
                  {{ item.meta.title }}
                </el-breadcrumb-item>
              </el-breadcrumb>
            </div>
          </div>
          <!-- 头部右边 -->
          <div class="header-right">

          </div>
        </div>
        <!-- 内容区 -->
        <div :class="showBread?'out':'out_all'">
          <el-card class="box-card">
            <router-view></router-view>
          </el-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import WebSocket from "@/components/widget/WebSocket"
import LoginAccountButton from "@/components/widget/LoginAccount";
import variables from '@/styles/variables.scss'
import router from "@/router";

export default {
  name: 'Home',
  components: {
    WebSocket,
    LoginAccountButton
  },
  data() {
    return {
      // 是否折叠
      isCollapse: false,
      // 面包屑的值
      breadData: [],
      // 菜单值
      menuData: [],
      single: false,
      showBread: false
    }
  },
  computed: {
    variables() {
      return variables
    },
  },
  methods: {
    // 获取active高亮index(注：解决内部有router-view的无法高亮的问题 author:PeterLee date:2021-10-12)
    getActive(path) {
      if (path === '/overview/overview') {
         this.showBread = false;
      } else {
         this.showBread = true;
      }
      // 第三个斜杠
      let index = this.findNthOccurrenceIndex(path, "/", 3);
      // index==-1表示没有第三个斜杠，则不用截取
      if (index === -1) {
        return path;
      }
      console.log(index)
      // 截取到二级菜单路径，及对应二级菜单的index,使之高亮
      return path.substring(0, index);
    },
    // 找第几个斜杠的位置
    findNthOccurrenceIndex(str, searchChar, nth) {
      // 输入验证
      if (typeof str !== 'string' || str.length === 0) {
        throw new Error('Input must be a non-empty string.');
      }
      if (typeof searchChar !== 'string' || searchChar.length !== 1) {
        throw new Error('Search character must be a single character string.');
      }
      if (typeof nth !== 'number' || nth < 1) {
        throw new Error('Nth occurrence must be a positive integer.');
      }
      let count = 0;
      let index = -1;
      while (true) {
        index = str.indexOf(searchChar, index + 1);
        if (index === -1) {
          break; // 没有找到新的匹配，退出循环
        }
        count++; // 匹配计数增加
        if (count === nth) {
          break; // 找到第 nth 个匹配，退出循环
        }
      }
      // 根据是否找到第 nth 个匹配返回索引或 -1
      return count === nth ? index : -1;
    },
    // 一级菜单打开时
    handleOpen(key, keyPath) {
      // console.log(key, keyPath);
    },
    // 一级菜单关闭时
    handleClose(key, keyPath) {
      // console.log(key, keyPath);
    },
    // 设置面包屑
    setBread() {
      this.breadData = this.$route.matched
    },
    // 侧边导航菜单
    setMenu() {
      // console.log("菜单", this.$router)
      // const rotues =this.$router.options.routes;
      const routes = this.$store.state.permission.routes;
      this.menuData = JSON.parse(JSON.stringify(routes))
      // 筛选掉path为"/"的内容
      this.menuData = this.menuData.filter((item) => {
        return item.path !== "/"
      })
      // 浏览器运行提示(非electron运行)
      const userAgent = navigator.userAgent.toLowerCase();
      if (userAgent.indexOf('electron/') === -1){
        this.menuData = this.menuData.filter((item) => {
          // 排除数字开发模块
          return item.path !== "/data"
        })
      }
    },
    logout() {
      this.$confirm('您是否确认退出登录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$store.dispatch('user/logout')
      }).catch(() => {
        // this.$message({
        //   type: 'info',
        //   message: '已取消操作'
        // });
      });

    },
    // 跳转到概览页
    toOverview() {
      router.push('/').then(()=> {})
    }
  },
  // 创建时
  created() {
    this.setMenu()
    // const urlParam = this.$getUrlData();
    // console.log(urlParam)
    this.setBread()

    // 根据url判断是否显示菜单
    location.href.indexOf("single") !== -1 ? this.single = true : this.single = false;

  },
  updated() {
    // 设置面包屑
    this.setBread()
    // console.log(location)

    // 根据url判断是否显示菜单
    location.href.indexOf("single") !== -1 ? this.single = true : this.single = false;
  },
}
</script>
<style lang="scss" scoped>
@import '~@/styles/variables.scss';

.home {
  max-height: 94%;
  box-sizing: border-box;

  .el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 246px;
    min-height: 400px;
  }

  .el-menu {
    height: 100%;
    overflow-y: auto;
    border-right: none;
    scrollbar-width: none;
  }

  .el-menu::-webkit-scrollbar {
    display: none;
  }

  .aside {
    width: fit-content;
    height: 100%;
    // border-right: 1px solid #000;
    //background: $menuBg !important;

    //子菜单样式
    .is-active > .el-submenu__title {
      color: $subMenuActiveText !important;
    }

    & .nest-menu .el-submenu > .el-submenu__title,
    & .el-submenu .el-menu-item {
      // min-width: $sideBarWidth !important;
      background-color: $subMenuBg !important;

      &:hover {
        background-color: $subMenuHover !important;
      }
    }

    //& .el-menu-item {
    //  background-color: $subMenuBg !important;
    //}
  }

  .view-container {
    flex: 1;
    height: 100%;

    .page-header {
      padding-top: 10px;
      background: #F2F5F9;
      height: 0.8rem;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .header-left {
        display: flex;

        .fold {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 0.45rem;
          padding-left: 0.3rem;
        }

        .bread {
          font-size: 0.3rem;
          margin-left: 0.3rem;
          font-weight: bold;
          display: flex;
          align-items: center;
        }
      }

      .header-right {
        display: flex;
        justify-items: center;
        align-items: center;
        margin-right: 0.8rem;
      }
    }

    .out {
      height: calc(100% - 0.96rem);
      width: 100%;
      //线上需要配置此处，开发时隐藏
      @if ($env == 'production') {
        height: 100%; // 生产环境展示
      } @else {
        //height: 100%; // 开发环境展示
      }
      padding: 10px;
      box-sizing: border-box;
      background: #F2F5F9;

      .el-card {
        height: 100%;
        border-radius: 0.2rem;
        //width: 100%;

        // box-sizing: border-box;
        /deep/ .el-card__body {
          height: 100%;
          overflow: auto;
          // width: 100%;
          box-sizing: border-box;
        }
      }
    }

    .out_all {
      height: 100%;
      width: 100%;
      ////线上需要配置此处，开发时隐藏
      //@if ($env == 'production') {
      //  height: 100%; // 生产环境展示
      //} @else {
      //  //height: 100%; // 开发环境展示
      //}
      padding: 10px;
      box-sizing: border-box;
      background: #F2F5F9;

      .el-card {
        height: 100%;
        border-radius: 0.2rem;
        //width: 100%;

        // box-sizing: border-box;
        /deep/ .el-card__body {
          height: 100%;
          overflow: auto;
          // width: 100%;
          box-sizing: border-box;
        }
      }
    }
  }
}
</style>
