import {currentUserInfo, login, logout, register, resetUserPassword} from "@/api/systemApi"
import router from "@/router"
import {enumDictItems} from "@/api/baseApi";
import {Message} from "element-ui";
const userTokenKey = 'UserTokenKey'
const rememberedKey = 'rememberedKey'

export default {
    namespaced: true,
    state: {
        // 全局'用户信息'缓存
        UserInfo:{},
        // 全局token缓存
        get UserToken() {
            if (this.remembered) {
                return localStorage.getItem(userTokenKey)
            }
            return sessionStorage.getItem(userTokenKey)
        },
        set UserToken(value) {
            if (this.remembered) {
                localStorage.setItem(userTokenKey, value)
                return
            }
            sessionStorage.setItem(userTokenKey, value)
        },
        get remembered() {
            return localStorage.getItem(rememberedKey) === 'true'
        },
        set remembered(value) {
           localStorage.setItem(rememberedKey,value)
        },
        // 枚举字典相关存储（value对应label）
        get enumDict() {
            return JSON.parse(localStorage.getItem('enumDict'))
        },
        set enumDict(value) {
            localStorage.setItem('enumDict', JSON.stringify(value))
        },
    },
    getters: {
        getPermission(state) {
            const info = state.UserInfo
            if(!info||!info['permissions']){
                return [];
            }
            return  info['permissions'];
        },
        getRoles(state) {
            const info = state.UserInfo
            if(!info||!info['roles']){
                return [];
            }
            return  info['roles'];
        },
        getUserInfo(state) {
            return state.UserInfo
        }
    },
    mutations: {
        setUserInfo(state,userInfo) {
            state.UserInfo = userInfo
        },
        setUserToken(state,token) {
            state.UserToken = token;
        },
        //保存枚举字典 字典相关存储（value对应label）
        setEnumDict(state,data){
            state.enumDict=data;
        },
        setRemembered(state, value) {
            state.remembered = value;
        }
    },
    actions: {
        /**
         * 重置密码
         * @param state
         * @param commit
         * @param data
         * @returns {Promise<unknown>}
         */
        resetPassword({state,commit},data) {
            return new Promise((resolve, reject) => {
                resetUserPassword(data).then((res) => {
                    if (res.code === 200) {
                        // message显示
                        Message.success({
                            message: '重置密码成功,请重新登录'
                        })
                        resolve(res);
                    } else {
                        reject(res);
                    }
                }).catch(()=>{
                        reject();
                });
            })
        },
        /**
         * 用户注册
         * @param state
         * @param commit
         * @param data
         * @returns {*|Promise<unknown>}
         */
        register({state,commit},data) {
            return new Promise((resolve, reject) => {
                register(data).then((res) => {
                    if (res.code === 200) {
                        // message显示
                        Message.success({
                            message: '注册成功,欢迎使用redid,即将跳转登录'
                        })
                        resolve(res);
                    } else {
                        reject(res);
                    }
                }).catch(()=>{
                    reject();
                })
            })
        },
        /**
         * 用户登录
         * @param state
         * @param commit
         * @param dispatch
         * @param data
         */
        login({state,commit,dispatch},data) {
            // 缓存是否记住密码
            commit('setRemembered',data.remembered)
            login(data).then(res => {
                if (res.code === 200) {
                    // message显示
                    Message.success({
                        message: '登录成功,欢迎使用redid'
                    })
                    // 缓存token
                    commit('setUserToken', res.data);
                    // dispatch('getUserInfo');
                    router.push('/').then(()=> {})
                }
            })
        },
        /**
         * 获取用户信息
         * @param commit
         * @param dispatch
         */
        getUserInfo({commit,dispatch}) {
            currentUserInfo().then(res => {
                if (res.code === 200) {
                    commit('setUserInfo', res.data);
                    // router.push('/system').then(r => {});
                }else{
                    dispatch('logout')
                }
            }).catch(() =>{
                dispatch('logout')
            })
        },
        /**
         * 枚举字典获取
         * @param commit
         * @param state
         * @param moduleName
         * @param code
         * @returns {Promise<unknown>}
         */
        getEnumDictItems({commit,state},{moduleName,code}){
            return new Promise((resolve,reject) =>{
                if(state.enumDict&&state.enumDict[code]){
                    resolve(state.enumDict[code])
                    return;
                }
                if(code){
                    enumDictItems(moduleName,code).then(res=>{
                        if(res.code===200){
                            let enumDict = state.enumDict;
                            //判断对象是否为空，为空就定义
                            if(!enumDict){
                                enumDict={}
                            }
                            const options=[];
                            const list = res.data;
                            //改变字典项值的类型
                            if(list){
                                list.forEach(item=>{
                                    //字典类型为number,type为0时为String,默认为String,不用转类型
                                    options.push({value:item.value,label:item.label,style:item.style})
                                })
                            }
                            enumDict[code]=options;
                            commit('setEnumDict',enumDict);
                            resolve(options)
                        }else{
                            resolve([])
                        }
                    }).catch(error =>{
                        reject(error)
                    })
                }
            })
        },
        /**
         * 退出登录
         * @param commit
         */
        logout({commit}) {
            logout().then(res => {
                if(res.code === 200) {
                    //删除token
                    commit('setUserToken', '');
                    commit('setUserInfo', '');
                    commit('setEnumDict', null);
                    //退出登录成功，刷新页面
                    router.push('/userLogin').then(()=> {})
                }
            })
        },
        /**
         * 前端退出登录
         * @param commit
         */
        fedLogout({commit}) {
            //删除token
            commit('setUserToken', '');
            commit('setUserInfo','');
            commit('setEnumDict', null);
            //退出登录成功，刷新页面
            location.href = '/';
        }
    }
}
